import styled from "styled-components";
import React, { FC } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, graphql, useStaticQuery } from "gatsby";

import Blog from "../../components/blog";
import SEO from "../../components/seo";

const MainPost = styled.div`
  padding: 20px 0;
  text-align: center;

  h4 {
    margin-top: 15px;
  }

  h5 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1em;
  }

  img {
    max-width: 100%;
  }
`;

const Post = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;

  & > img {
    display: block;
    max-width: 100%;
    margin: 0;
  }

  & > h6 {
    margin-top: 8px;
  }
`;

const RecentPost = styled.div``;

const index = ({ data }) => {
  const posts = data.allPost.nodes;
  const latestPost = posts[0];
  const recentPosts = posts.slice(1);

  return (
    <Blog>
      <SEO title="Blog" article />
      {latestPost && (
        <MainPost>
          <img src={latestPost.imageUrl} alt={latestPost.title} />
          <h4>
            <Link to={`/blog/${latestPost.slug}`}>{latestPost.title}</Link>
          </h4>
          <h5>By {latestPost.author}</h5>
        </MainPost>
      )}
      {recentPosts.length > 0 && (
        <Row>
          {recentPosts.map((post) => (
            <Col lg={3}>
              <Post key={post.slug}>
                <img src={post.imageUrl} alt={post.title} />
                <h6>
                  <Link to={`/blog/${post.slug}`}>{post.title}</Link>
                </h6>
              </Post>
            </Col>
          ))}
        </Row>
      )}
    </Blog>
  );
};

export const query = graphql`
  query {
    allPost {
      nodes {
        slug
        title
        imageUrl
        author
      }
    }
  }
`;

export default index;
